import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/Login";
import SSOLogin from "@/views/SSOLogin";
import Error from "@/views/Error";
import AdminLayout from "@/layouts/AdminLayout";
import NotFound from "@/views/NotFound";
import UserCourses from "@/views/UserCourses";
import store from "@/store";
import UpdateIU from "@/views/UpdateIU.vue";
import UserRegistration from "@/views/UserRegistration.vue";
import axios from "axios";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/admin',
    component: Home
  },
  {
    path: '/users/:userId/courses',
    name: 'UserCourses',
    component: UserCourses
  },
  {
    path: '/sso/logout',
    name: 'ssoLogout',
    beforeEnter: async (to) => {
      console.log(to.query.email);
      // Definiere die URLs der WordPress-Seiten, von denen ausgeloggt werden soll

      const wordpressSites = [
        'https://www.stuck-verband.de/wp-json/s3m/v1/logout',
        'https://www.stuck-komzet.de/wp-json/s3m/v1/logout',
        'https://shop.ausbau-akademie.de/wp-json/s3m/v1/logout'
      ];

      // Die jeweiligen Bearer-Tokens für die URLs
      const tokens = [
        'sxljx30mzuq3swzlpn0pnphlkonbsdeyv4gax7qmc0axbcwd4zmrotupe527zwbfcj',
        'tlhza8qgl197p99gk944elnzz4k4atoe84r0gq0hbws832371kyl3a63xbp7rvhvl3',
        'ru15dmar65scmyn7lil9joex5vc8tb4kubcy0yx6tltp3wxh22jxfe4cq3opesei'
      ];

      if(to.query.email) {
        try {
          await Promise.all(wordpressSites.map((url, index) =>
              axios.post(url, {
                "email": to.query.email
              }, {
                headers: {
                  'Authorization': `Bearer ${tokens[index]}`,
                  'Content-Type': 'application/json'
                }
              })
          ));
          console.log('Successfully logged out from all sites');
        } catch (error) {
          console.error('Fehler beim Logout von den WordPress-Seiten:', error);
        }
      }
      await store.dispatch('logout')
      if(to.query.redirect_uri) {
        window.location.href= to.query.redirect_uri
      }
      return false
    },
  },
  {
    path: '/sso/login',
    name: 'ssoLogin',
    component: SSOLogin
  },
  {
    path: '/updateInternetUser',
    name: 'updateIU',
    component: UpdateIU
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminLayout,
    children: [
      {
        path: 'home',
        name: 'adminHome',
        component: Home
      },
      {
        path: 'registration',
        name: 'registration',
        component: UserRegistration
      },
      {
        path: 'login',
        name: 'adminLogin',
        component: Login
      },
      {
        path: 'courses',
        name: 'courses',
        component: () => import(/* webpackChunkName: "courseList" */ '../views/CourseList')
      },
      {
        path: 'chapters',
        name: 'chapters',
        component: () => import(/* webpackChunkName: "chapterList" */ '../views/ChapterList')
      },
      {
        path: 'lessons',
        name: 'lessons',
        component: () => import(/* webpackChunkName: "chapterList" */ '../views/LessonList')
      },
      {
        path: 'products',
        name: 'products',
        component: () => import(/* webpackChunkName: "groupList" */ '../views/ProductList')
      },
      {
        path: 'licences',
        name: 'licences',
        component: () => import(/* webpackChunkName: "groupList" */ '../views/LicenceList')
      },
      {
        path: 'licenceAssignments/:userId',
        name: 'licenceAssignments',
        props: true,
        component: () => import(/* webpackChunkName: "groupList" */ '../views/LicenceAssignmentList')
      },
      {
        path: 'usersorig',
        name: 'usersOrig',
        component: () => import(/* webpackChunkName: "userList" */ '../views/UserListOrig')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import(/* webpackChunkName: "userList" */ '../views/UserList')
      },
      {
        path: 'aps',
        name: 'aps',
        component: () => import(/* webpackChunkName: "userList" */ '../views/APList')
      },
      {
        path: 'companies',
        name: 'companies',
        component: () => import(/* webpackChunkName: "userList" */ '../views/UserList'),
        props: (route) => ({
          user: null,
          ...route.params
        })
      },
      {
        path: 'employees/:userId',
        name: 'employees',
        props: true,
        component: () => import(/* webpackChunkName: "userList" */ '../views/EmployeeList')
      },
      {
        path: 'groups',
        name: 'groups',
        props: true,
        component: () => import(/* webpackChunkName: "userList" */ '../views/GroupList')
      },
      {
        path: 'sammelgruppen',
        name: 'sammelgruppen',
        props: true,
        component: () => import(/* webpackChunkName: "userList" */ '../views/SGroupList')
      },
      {
        path: 'settings',
        name: 'settings',
        meta: {
          kundeTyp: ['F'],
        },
        component: () => import(/* webpackChunkName: "userList" */ '../views/Settings')
      },
      {
        path: 'azubi',
        name: 'azubi',
        meta: {
          kundeTyp: ['F'],
        },
        component: () => import(/* webpackChunkName: "userList" */ '../views/Azubi')
      },
      {
        path: 'bsuche',
        name: 'bsuche',
        meta: {
          role: ['admin', 'author', 'user'],
          kundeTyp: ['F'],
          groups: ["RATG-1", "RATG-2"]
        },
        component: () => import(/* webpackChunkName: "userList" */ '../views/BSuche')
      },
      {
        path: 'statistics/:userId',
        name: 'statistics',
        props: true,
        component: () => import(/* webpackChunkName: "userList" */ '../views/Statistics')
      }

    ]
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (store.state.user?.role !== "admin" && (to.meta.role || to.meta.kundeTyp || to.meta.groups)) {
    if(!store.state.user && to.path !== '/admin'){
      next('/admin')
    }
    const userGroupIds = store.state.user?.kundeObj?.gruppen?.map(group => group.Kurzbezeichnung) || [];
    const hasGroupMatch = to.meta.groups?.some(group => userGroupIds.includes(group));
    if((!to.meta.role || to.meta.role.includes(store.state.user?.role)) &&
        (!to.meta.kundeTyp || to.meta.kundeTyp.includes(store.state.user?.kundeObj?.KundeTyp) &&
            (typeof hasGroupMatch === 'undefined' || hasGroupMatch))){
      next();
    }else{
      next( '/sso/logout')
    }

  }else{
    if(to.path === '/'){
      next('/admin/home');
      return;
    }
    next();
  }
});

export default router
