import APIService from "@/services/APIService";
import gruppeService from "@/services/gruppe.service";

import userService from "@/services/user.service"; // eslint-disable-line no-unused-vars
import kundeapService from "@/services/kundeap.service";
import sammelgruppeService from "@/services/sammelgruppe.service";
import APIServiceSync from "@/services/APIServiceSync"; // eslint-disable-line no-unused-vars

export default {
    // eslint-disable-next-line no-unused-vars
    async getModel(filter = [], schema = [], id = ""){
        if(id != ""){
            let model = await APIService.getModel("gruppemitglied", id);
            model.schema = schema;
            return model;
        }else{
            let model = await APIService.getAllModel("gruppemitglied", filter);
            for(let i=0; i < model.length; i++){
                model[i].schema = schema;
            }
            return model;
        }
    },
    async deleteGruppenForKunde(kunde){
        let gruppenmitglied = await this.getGruppeMitgliedForKunde(kunde.id, kunde.KundeTyp)
        for(let i=0; i < gruppenmitglied.length; i++) {
            console.log(gruppenmitglied[i]);
            if(kunde.InternetUser){
                gruppenmitglied[i].InternetUser = kunde.InternetUser;
            }
            await this.deleteModel(gruppenmitglied[i]);
        }
    },
    async getGruppeMitgliedForKunde(kundeid, typ){
        let gruppenmitglied;
        if(typ === "P"){
            gruppenmitglied = await this.getModel([{"name": "P_Kunde", "value": kundeid}, {"name": "deep", "value": true}]);
        }else{
            gruppenmitglied = await this.getModel([{"name": "F_Kunde", "value": kundeid}, {"name": "deep", "value": true}]);
        }
        return gruppenmitglied;
    },
    async getGruppenForKunde(kundeid, typ){
        console.log("getGruppeForKunde "+kundeid+" - "+typ);
        let gruppenmitglied = [];
        if(typ === "P"){
            gruppenmitglied = await this.getModel([{"name": "P_Kunde", "value": kundeid}, {"name": "deep", "value": true}]);
        }else{
            gruppenmitglied = await this.getModel([{"name": "F_Kunde", "value": kundeid}, {"name": "deep", "value": true}]);
        }
        let gruppen = [];
        for(let i=0; i < gruppenmitglied.length; i++) {
            gruppenmitglied[i].id = gruppenmitglied[i].id ?? gruppenmitglied[i]._id;
            if(gruppenmitglied[i].Gruppe != null && gruppenmitglied[i].Gruppe !== undefined && gruppenmitglied[i].Gruppe.length > 0){
                const originalMember = gruppenmitglied[i];
                const gmtmp = { ...originalMember };
                delete gmtmp.Gruppe;
                gruppenmitglied[i].Gruppe[0].id = gruppenmitglied[i].Gruppe[0]._id !== undefined ? gruppenmitglied[i].Gruppe[0]._id : gruppenmitglied[i].Gruppe[0].id;
                gruppenmitglied[i].Gruppe[0].Gruppemitglied = gmtmp;
                gruppen.push(gruppenmitglied[i].Gruppe[0]);
            }
        }

        /*
        for(let i=0; i < gruppenmitglied.length; i++){
            let gruppeTmp = await gruppeService.getModel(gruppenmitglied[i].Gruppe);
            let duplicate = false;
            for(let j=0; j < gruppen.length; j++){
                if(gruppen[j].id == gruppeTmp.id){
                    duplicate = true;
                    break;
                }
            }
            if(duplicate){
                console.log("delete duplicate? "+gruppenmitglied[i].id);
                try {
                    //await this.deleteModel(gruppenmitglied[i]);
                }catch (ex){
                    console.log(ex);
                }
            }else{
                gruppeTmp.gruppemitglied = gruppenmitglied[i];
                gruppen.push(gruppeTmp);
            }
        }*/
        return gruppen;
    },
    async getGruppenForKundeAndFirma(kundeid, firmaid){
        console.log("getGruppeForKunde "+kundeid);
        let gruppenmitglied = await this.getModel([{"name": "P_Kunde", "value": kundeid},{"name": "F_Kunde", "value": firmaid}]);
        console.log(gruppenmitglied);
        let gruppen = [];
        for(let i=0; i < gruppenmitglied.length; i++){
            let gruppeTmp = await gruppeService.getModel(gruppenmitglied[i].Gruppe);
            let duplicate = false;
            for(let j=0; j < gruppen.length; j++){
                if(gruppen[j].id == gruppeTmp.id){
                    duplicate = true;
                    break;
                }
            }
            if(duplicate){
                console.log("delete duplicate? "+gruppenmitglied[i].id);
                try {
                    //await this.deleteModel(gruppenmitglied[i]);
                }catch (ex){
                    console.log(ex);
                }
            }else{
                gruppeTmp.Gruppemitglied = gruppenmitglied[i];
                gruppen.push(gruppeTmp);
            }
        }
        return gruppen;
    },
    async getMitgliederForGruppe(gruppeid){
        let gruppenmitglied = await this.getModel([{"name": "Gruppe", "value": gruppeid}, {"name": "deep", "value": true}]);
        console.log(gruppenmitglied);
        let kunden = [];

        for(let i=0; i < gruppenmitglied.length; i++){
            console.log("get model for kunde gruppenmitglied P: "+gruppenmitglied[i].P_Kunde+" F: "+gruppenmitglied[i].F_Kunde);
            console.log(gruppenmitglied[i]);
            console.log(gruppenmitglied[i].F_Kunde);
            let kundeTmp = {};
            kundeTmp.gruppemitglied = gruppenmitglied[i];
            if(gruppenmitglied[i].P_Kunde && gruppenmitglied[i].P_Kunde !== "" && gruppenmitglied[i].P_Kunde !== "64fdf12db647990028793c35" && gruppenmitglied[i].P_Kunde !== "650d29e476de890027ba9ba5" && gruppenmitglied[i].F_Kunde && gruppenmitglied[i].F_Kunde !== "" && gruppenmitglied[i].F_Kunde !== "64fdf12db647990028793c36" && gruppenmitglied[i].F_Kunde !== "650d29e476de890027ba9ba6"){
                console.log("AP");
                const user = { ...kundeTmp };
                kundeTmp = await userService.getModel(gruppenmitglied[i].P_Kunde);
                kundeTmp.KundeTyp = "AP";
                kundeTmp.ap = await kundeapService.getAPForGruppe(user.gruppemitglied.P_Kunde, user.gruppemitglied.F_Kunde);
                kundeTmp.ap.user = user;
                kundeTmp.InternetUser = kundeTmp.ap.InternetUser;
                kundeTmp.UKeyKunde = kundeTmp.ap.UKeyKundeAp;
            }else if(gruppenmitglied[i].P_Kunde && gruppenmitglied[i].P_Kunde !== "" && gruppenmitglied[i].P_Kunde !== "64fdf12db647990028793c35" && gruppenmitglied[i].P_Kunde !== "650d29e476de890027ba9ba5"){
                console.log("P");
                kundeTmp = await userService.getModel(gruppenmitglied[i].P_Kunde);
            }else if(gruppenmitglied[i].F_Kunde && gruppenmitglied[i].F_Kunde !== "" && gruppenmitglied[i].F_Kunde !== ""){
                console.log("F");
                kundeTmp = await userService.getModel(gruppenmitglied[i].F_Kunde);
            }

            console.log(kundeTmp.id);
            if(!kundeTmp.id){
                console.log("kunde not found. delete gruppemitglied?");
            }else{
                kunden.push(kundeTmp);
            }

        }
        return kunden;
    },
    async saveModel(model, syncWithPages = true, syncWithLerngruppe = true){
        const _model = { ...model }
        //prepare for save
        delete _model.schema;
        delete _model.InternetUser;
        delete _model.schemaType;

        //Rest
        delete _model.RestTimestampCreate;
        delete _model.RestTimestampLast;

        let res;
        res = await APIService.createModel("gruppemitglied", _model);
        if(res != null){
            if(syncWithLerngruppe){
                await this.syncGruppeToLernGruppe(model.Gruppe);
            }
            model.id = res.id;
            if(syncWithPages){
                await gruppeService.syncGruppe(model.Gruppe, model.InternetUser, true);
            }
        }
        return res;
    },
    async deleteModel(model, syncWithPages = true, syncWithLerngruppe = true){
        console.log("delete model");
        console.log(model);
        if(syncWithLerngruppe){
            await this.syncGruppeToLernGruppe(model.Gruppe);
        }
        if(syncWithPages){
            await gruppeService.syncGruppe(model.Gruppe, model.InternetUser, false);

        }
        if(!model.id) model.id = model._id;
        return await APIService.deleteModel("gruppemitglied", model.id);
    },

    async syncGruppeToLernGruppe(gruppe_id){
        let sammelgruppen = await sammelgruppeService.getSammelgruppenForGruppe(gruppe_id, true);
        console.log("sammelgruppen "+gruppe_id);
        sammelgruppen.forEach((sg) => {
            console.log(sg);
            if(sg.licenceExists){
                console.log("exists");
                APIServiceSync.syncBG2LD(sg.id);
            }
        });
    },

    createSimpleModel(gruppeId, kundeId, type){
        const current = new Date();
        let month = current.getMonth()+1;
        month = (month < 10) ? '0'+month : month;
        let day = current.getDate();
        day = day < 10 ? '0'+day : day;
        const date = `${current.getFullYear()}-${month}-${day}`;
        let model = {};
        if(type === "F"){
            model = {
                Gruppe: gruppeId,
                GruppeFunktion: "643562aa7304db0027195b0a",
                F_Kunde: kundeId,
                Datum: date
            };
        }else{
            model = {
                Gruppe: gruppeId,
                GruppeFunktion: "643562aa7304db0027195b0a",
                P_Kunde: kundeId,
                Datum: date
            };
        }

        return model;
    }
}
