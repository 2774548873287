import axios from 'axios'
const serverURL = process.env.VUE_APP_API_SYNC

const config = {
    baseURL: serverURL + '',
    //baseURL: 'https://webquod.de/wq/rest',
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 1200000
}

const apiClient = axios.create(config)
apiClient.interceptors.request.use((config) => {
    const token = process.env.VUE_APP_API_TOKEN_SYNC
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})
export default {

    syncBG2LD(id = ''){
        return apiClient.get("/bg2lg/?data="+id).then(result => {
           console.log("syncbg2ld:", result.data)
            if(result.data){
                return result.data.data
            }
            return {}
        })
    },
    syncUserRegistration(model){
        return apiClient.post("/registration", model).then(result => {
            console.log(result);
            return true;
        }).catch(error => {
            if (error.response.status !== 409) { //InternetUser bereits vorhanden
                return false;
            }
            return false;
        });
    },
}
