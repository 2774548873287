<template>
  <v-app>
    <v-navigation-drawer
        v-if="showMenu"
        :value="isLoggedIn"
        clipped
        app
        v-model="navDrawer"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="user && user.avatarUrll">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user && (user.firstName || '') + ' ' + (user.lastName || '') }}</v-list-item-title>
            <v-list-item-subtitle>{{ user && (user.email || '') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list>
        <template v-if="isLoggedIn && user.role ==='user'">
          <v-list-item v-if="user.hasTeamLicences" :to="`/admin/employees/${user.id}`">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mein Team</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.hasTeamLicences" :to="`/admin/licenceAssignments/${user.id}`">
            <v-list-item-icon>
              <v-icon>mdi-certificate</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Meine Lizenzen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.isCourseManager" :to="`/admin/courses`">
            <v-list-item-icon>
              <v-icon>mdi-school</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Kurse managen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.kundeObj && user.kundeObj.kundeTyp === 'F'" to="/admin/settings">
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Einstellungen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </template>
        <template v-if="isLoggedIn && allDataLoaded">
          <template v-for="item in filteredMenuItems">

            <v-list-item v-if="!item.items" :key="item.link" :to="item.link">
              <v-list-item-icon>
                <v-icon v-text="item.icon"/>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
            </v-list-item>
            <v-list-group
                v-else
                :key="item.title"
                v-model="item.active"
                :prepend-icon="item.icon"
                no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  v-for="child in item.items"
                  :key="child.title"
                  :to="child.link"
              >
                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </template>
        <template v-if="managedUsers.length > 0">
          <v-divider/>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">Von mir verwaltet:</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              v-for="user in managedUsers"
              :key="user.id"
              sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{getTitle(user)}}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="({title, icon, link}, i) in management"
                :key="i"
                link
                :to="`${link}/${user.id}`"
            >
              <v-list-item-title v-text="title"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <v-divider/>
        <v-list-item @click="doLogout">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        clipped-left
        app
        color="primary"
        dark
        dense
    >
      <div class="d-flex align-center">
        Stuck-Admin
      </div>

      <v-spacer></v-spacer>
      <v-btn v-if="editUser" @click="endUserEdit">Bearbeiten von {{ this.editUser && this.editUser.email }} beenden</v-btn>
      <div v-if="!navDrawer" @click="navDrawer = !navDrawer" class="d-flex align-center admin_area_icon" style="" >
        <v-icon >mdi-menu</v-icon>
      </div>
    </v-app-bar>

    <v-main>
      <v-progress-linear indeterminate :active="true" :color="progressActive ? 'red' : 'white'"></v-progress-linear>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
      <Spinner v-if="loading" class="s3m-spinner global-spinner" name="circle" color="#008bc4" />
    </v-main>
  </v-app>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import store from "@/store";

export default {
  name: "AdminLayout",
  data () {
    return {
      allDataLoaded: false,
      itemsOrig: null,
      instances: null,
      progressActive: false,
      navDrawer: true,
      openFirmenEinstellungen: true,
      items: [
        {
          title: 'Personen',
          icon: 'mdi-account',
          link: '/admin/users?filter=KundeTyp&value=P',
          auth: ['admin', 'sale'],
        },
        {
          title: 'Firmen',
          icon: 'mdi-domain',
          link: '/admin/companies?filter=KundeTyp&value=F',
          auth: ['admin', 'sale'],
        },
        {
          title: 'Alle Ansprechpartner',
          icon: 'mdi-account',
          link: '/admin/aps',
          auth: ['admin', 'sale'],
        },
        {
          title: 'Berechtigungsgruppen',
          icon: 'mdi-home-group',
          link: '/admin/sammelgruppen',
          auth: ['admin', 'sale'],
        },
        {
          title: 'Gruppen',
          icon: 'mdi-account-group',
          link: '/admin/groups',
          auth: ['admin', 'sale'],
        },
        {
          title: 'Kundendaten',
          icon: 'mdi-account',
          link: '/admin/settings',
          kundeTyp: ['F'],
          auth: ['author', 'user'],
        },
        {
          title: 'Services',
          icon: 'mdi-cog',
          auth: ['author', 'user'],
          kundeTyp: ['F'],
          active: true,
          items: [{
            title: 'Mission STKTR',
            icon: 'mdi-account-search',
            link: '/admin/azubi',
            auth: ['admin', 'author', 'user'],
            kundeTyp: ['F']
          },{
            title: 'Ratgeber R+F',
            icon: 'mdi-home-search',
            link: '/admin/bsuche',
            auth: ['admin', 'author', 'user'],

            kundeTyp: ['F']
          }]
        },
        {
          title: 'LLS',
          icon: 'mdi-school',
          auth: ['admin','sale'],
          items: [        {
            title: 'Lerngruppen',
            icon: 'mdi-account-group',
            link: '/admin/licences',
            auth: ['admin', 'sale'],
          },{
            title: 'Kurs-Bundles',
            icon: 'mdi-bookshelf',
            link: '/admin/products',
            auth: ['admin','sale'],
          },{
            title: 'Kurse',
            icon: 'mdi-book-multiple',
            link: '/admin/courses',
            auth: ['admin','author'],
          },
          {
            title: 'Kapitel',
            icon: 'mdi-book',
            link: '/admin/chapters',
            auth: ['admin','author'],
          },
          {
            title: 'Lektionen',
            icon: 'mdi-newspaper-variant',
            link: '/admin/lessons',
            auth: ['admin','author'],
          },
            {
              title: 'Alle User Logins',
              icon: 'mdi-account-group',
              link: '/admin/usersorig',
              auth: ['admin'],
            }]
        },
        {
          title: 'Firmen-Einstellungen',
          icon: 'mdi-cog',
          auth: ['admin'],
          active:false,
          items: [       {
            title: 'Kundendaten',
            icon: 'mdi-account',
            link: '/admin/settings',
            auth: ['admin'],
          },{
            title: 'Mission STKTR',
            icon: 'mdi-account-search',
            link: '/admin/azubi',
            auth: ['admin'],
          },{
            title: 'Ratgeber R+F',
            icon: 'mdi-home-search',
            link: '/admin/bsuche',
            auth: ['admin'],
          }]
        }
      ],
      management: [
        {title: 'Team', icon: 'mdi-account-group', link: '/admin/employees'},
        {title: 'Lizenzen', icon: 'mdi-certificate', link: '/admin/licenceAssignments'},
        {title: 'Statistik', icon: 'mdi-chart-line', link: '/admin/statistics'},
      ],
    }
  },
  methods: {
    ...mapActions(['fetchKunde', 'fetchSammelgruppen', 'fetchGruppen']),
    endUserEdit(){
      this.$store.commit('clearEditUser')
    },
    isUserAuthor() {
      return this.user.role === 'author';
    },
    doLogout() {
      this.$store.dispatch('logout').then(() =>{
        this.$router.push({name: 'ssoLogin'})
      })
    },
    getTitle(user) {
      return user ?  user.companyName ? user.companyName : `${user.firstName} ${user.lastName}` : ''
    },
    filterMenuItems(items) {
      items = JSON.parse(JSON.stringify(items));
      // Filtert die Menüelemente basierend auf den Auth-Berechtigungen
      let itemsTmp = items.filter(item => {
        if(item.items) {
          item.items = this.filterMenuItems(item.items);
        }
        const userGroupIds = this.user?.kundeObj?.gruppen?.map(group => group.Kurzbezeichnung) || [];
        const hasGroupMatch = item.groups?.some(group => userGroupIds.includes(group));
        return (item.auth && item.auth.includes(this.user?.role)) &&
            (!item.kundeTyp || item.kundeTyp.includes(this.user?.kundeObj?.KundeTyp) &&
                (typeof hasGroupMatch === 'undefined' || hasGroupMatch)
            );
      });
      return itemsTmp;
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapState(['user', 'managedUsers', "editUser"]),
    filteredMenuItems() {
      return this.filterMenuItems(this.items);
    },
    showMenu() {
      return this.$route.path !== '/admin/registration';
    },
    loading() {
      console.log("loading: "+this.$store.state.loading);
      return this.$store.state.loading;
    },
  },
  async mounted() {
    this.$store.commit('setLoading', false);
    if (this.user && !this.user.kundeObj) {
      if (!this.user.origMail) this.user.origMail = this.user.email;
      await Promise.all([this.fetchKunde()]).catch((error) => {
        console.error("Ein Fehler ist aufgetreten: ", error);
      });
    }
    if (this.user.kundeObj && !this.user.kundeObj.gruppen) {
      await Promise.all([
        this.fetchGruppen(),
        this.fetchSammelgruppen()
      ]).catch((error) => {
        console.error("Ein Fehler ist aufgetreten: ", error);
      });
    }
    this.allDataLoaded = true;
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,

}
function beforeRouteEnterOrUpdate(to, from, next) {
  if (to.path === "/admin/registration") {
    next()
    return;
  }
  let user = store.state.user;

  if (!user && to.path !== "/admin/login" && to.path !== "/admin/registration") {
    next('/sso/login')
  } else if (to.path === "/admin") {
    let routeName = store.getters.hasRole('admin') ? 'users' :
        store.getters.hasRole('author') ? 'settings' :
            store.getters.hasRole('sale') ? 'products' :
                store.getters.hasRole('user') ? 'settings' : ''
    if (user.kundeObj?.KundeTyp === "P" && !store.getters.hasRole('admin')) {
      routeName = 'adminHome';
    }
    next({name: routeName, params: {userId: user.id}})
  } else if (user && to.path === "/admin/login") {
    next('/admin')
  }
  next();
}
</script>

<style scoped>

</style>
