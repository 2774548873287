import axios from 'axios'
import router from '@/router'
import store from '@/store'
import json from "../../public/db.json";

const config = {
    baseURL: `${process.env.VUE_APP_ROOT_API}`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 600000
}
let originalUrl = null

const apiClient = axios.create(config)

let firmaCache_valid = false;
let personCache_valid = false;
let personCache = [];
let firmaCache = [];

function authHeaderInterceptor(config) {
    const token = store.state?.user?.tokens?.access?.token
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}

function accessTokenRefreshInterceptor(error) {
    const status = error.response.status
    const config = error.config
    if(config.url !== '/auth/refresh-tokens') {
        originalUrl = config.url
    }
    if (status !== 401) {
        return Promise.reject(error);
    }
    if (config.url === '/auth/login'
        || config.url === '/sso/authTokens') {
        return Promise.reject(error);
    }

    // TODO check if user is disabled
    if (config.url === '/auth/refresh-tokens') {
        store.commit('logout')
        if(originalUrl !== '/sso/authCode') {
            router.push({ name: 'adminLogin'});
        }
        return Promise.reject(error);
    }
    const refreshToken = store.getters.refreshToken ? store.getters.refreshToken : 'invalid_token'
    if(!config._retry) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            const tokens = response?.data
            store.commit('updateTokens', tokens)
            config.headers['Authorization'] = `Bearer ${tokens?.access?.token}`;
            config._retry = true
            return apiClient.request(config)
        })
    }
}

apiClient.interceptors.request.use((config) => {
    return authHeaderInterceptor(config)
})
apiClient.interceptors.response.use(response => {
    return response
}, error => {
    return accessTokenRefreshInterceptor(error)
})
export default {
    login(email, password) {
        return apiClient.post('/auth/login', { email, password }).then(response => {
                return response.data
            }
        )
    },
    logout(refreshToken) {
        return apiClient.post('/auth/logout', { refreshToken }).catch((error) => {
            if (error.response && error.response.status !== 404) {
                throw error;
            }
        })
    },
    refreshToken(refreshToken) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            return response?.data
        })
    },
    sendAdminMail(subject, body){
        let message = {
            "subject": subject,
            "body": body,
            "to":"shop@bz-af.de"
        }
        return apiClient.post("/users/sendNotificationEmail", message).then(result => {
            return result.data
        });
    },
    sendUserMail(subject, body){
        let message = {
            "subject": subject,
            "body": body
        }
        return apiClient.post("/users/sendNotificationEmail", message).then(result => {
            return result.data
        });
    },
    getUsers() {
        return apiClient.get("/users").then(result => {
            return result.data.results
        })
    },
    getUserByEmail(email) {
        return apiClient.get("/users?email="+email).then(result => {
            return result.data.results[0]
        })
        //'/users?email='
    },
    getTeamMembers(userId) {
        return apiClient.get(`/users/${userId}/teamMembers`).then(result => {
            return result.data.results
        })
    },
    getManagedUsers(userId) {
        return apiClient.get(`/users/${userId}/managedUsers`).then(result => {
            return result.data.results
        })
    },
    addTeamMember(userId, member) {
        return apiClient.post(`/users/${userId}/teamMembers`, member).then(result => {
            console.log("Employees:", result.data)
        })
    },
    removeTeamMember(userId, memberId) {
        return apiClient.delete(`/users/${userId}/teamMembers/${memberId}`).then(result => result.data.results)
    },
    isEmailTaken(email) {
        return apiClient.get(`/users?email=${email}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    isProductNameTaken(productName) {
        return apiClient.get(`/products?name=${productName}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    saveUser(user) {
        const _user = {...user}
        delete _user.suchstring;
        _user.password = _user.password === '' ? undefined : _user.password
        return apiClient.post("/users", _user).then(result => {
            return result.data
        })
    },
    updateUser(user) {
        const userId = user.id
        const _user = {...user}
        delete _user.id
        delete _user.assignments
        delete _user.isRegistered
        delete _user.teamMemberships
        delete _user.teamMembers
        delete _user.userGroups
        delete _user.guid
        delete _user.completeName;
        delete _user.importedAt;
        delete _user.RestTimestampLast;
        delete _user.RestTimestampCreate;
        delete _user.kundeObj;
        delete _user.suchstring;
        if(!_user.firstName || _user.firstName === "") _user.firstName = " ";
        if(!_user.lastName || _user.lastName === "") _user.lastName = " ";
        return apiClient.patch(`/users/${userId}`, _user).then(result => {
            return result.data
        })
    },
    deleteUser(user) {
        return apiClient.delete(`/users/${user.id}`).then(result => {
            return result.data
        })
    },
    getGroup(group) {
        return apiClient.get(`/groups/${group.id}`).then(result => {
            return result.data.results
        })
    },
    getProducts() {
        return apiClient.get( "/products").then(result => {
            return result.data.results
        }).catch(error => {
            console.log("Error getting products", error)
            return Promise.reject(error)
        })
    },
    getProduct(product) {
        return apiClient.get( `/products/${product.id}`).then(result => {
            return result.data
        }).catch(error => {
            console.log("Error getting product", error)
            return Promise.reject(error)
        })
    },
    getLicences() {
        return apiClient.get( "/licences").then(result => {
            return result.data.results
        }).catch(error => {
            console.log("Error getting licences", error)
            return Promise.reject(error)
        })
    },
    getUserLicences(userId) {
        return apiClient.get( `users/${userId}/licences`).then(result => {
            return result.data.results
        }).catch(error => {
            console.log("Error getting licences", error)
            return Promise.reject(error)
        })
    },
    saveProduct(product) {
        if(!product.id) {
            return apiClient.post("/products", product).then(result => {
                return result.data
            })
        } else {
            const _product = {...product}
            const productId = product.id
            delete _product.id
            delete _product.createdAt
            delete _product.updatedAt
            delete _product.lastUpdatedBy
            delete _product.author
            return apiClient.patch(`/products/${productId}`, _product).then(result => {
                return result.data
            })
        }
    },
    saveLicence(licence) {
        if(!licence.id){
            return apiClient.post("/licences", licence).then(result => {
                return result.data
            })
        } else {
            const {startDate, expirationDate, quantityTotal, orderNumber, comment} =  licence
            return apiClient.patch(`/licences/${ licence.id }`, {
                startDate, expirationDate, quantityTotal, orderNumber, comment
            }).then(result => {
                return result.data
            })
        }
    },
    updateAssignments(licenceId, assignments) {
        return apiClient.put(`/licences/${licenceId}/assignments`, {assignments} ).then(result => {
            console.log(result)
        })
    },
    getAssignments(licenceId) {
        return apiClient.get(`/licences/${licenceId}/assignments`).then(result => result.data)
    },
    deleteLicence(licence) {
        return apiClient.delete(`/licences/${licence.id}`)
    },
    deleteProduct(product) {
        return apiClient.delete(`/products/${product.id}`)
    },
    getAuthCode(clientId, redirectUri, scope) {
        return apiClient.post(`/sso/authCode`, {clientId, redirectUri, scope}).then(result => {
            return result.data;
        })
    },
    getCourses(tags = undefined) {
        return apiClient.get( "/courses" + (tags ? "?tags=" + tags  : "")).then(result => {
            return result.data.results
        })
    },
    getUserCourses(userId) {
        return apiClient.get( `/users/${userId}/courses`).then(result => {
            return result.data
        })
    },
    deleteCourse(course) {
        return apiClient.delete(`/courses/${course.id}`)
    },
    saveCourse(course) {
        const _course = { ...course }
        _course.image = _course.image ? _course.image.id : undefined
        _course.events.forEach(event => delete event['id'])
        if(!course.id) {
            return apiClient.post("/courses", _course).then(result => {
                return result.data
            })
        } else {
            const courseId = course.id
            delete _course.id
            delete _course.createdAt
            delete _course.updatedAt
            delete _course.lastUpdatedBy
            delete _course.author
            return apiClient.patch(`/courses/${courseId}`, _course).then(result => {
                return result.data
            })
        }
    },
    getCourse(course) {
        return apiClient.get(`/courses/${course.id}`).then(result => {
            return result.data
        })
    },
    isCourseTitleTaken(courseTitle) {
        return apiClient.get(`/courses?name=${courseTitle}`).then((result) => {
            return result.data.totalResults > 0
        })
    },

    upload(file, onUploadProgress, extractZip = true, description = "") {
        let formData = new FormData();

        formData.append("file", file);
        formData.append("description", description)
        formData.append("unzipContent", extractZip.toString())

        return apiClient.post("/content/", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress,
            timeout: 0
        });
    },
    logoUpload(file, onUploadProgress, extractZip = false, description = "", filename = "") {
        let formData = new FormData();

        formData.append("file", file);
        formData.append("description", description)
        formData.append("unzipContent", extractZip.toString())
        formData.append("logoUpload", "true");
        if (!filename) {
            filename = file.name;
        }
        formData.append("logoFileName", filename);

        return apiClient.post("/content/", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress,
            timeout: 0
        });
    },

    setLogoName(logoName, kunde_id){
        let kunde = {};
        kunde.logoName = logoName;
        return apiClient.patch("/api/kunde/"+kunde_id, kunde).then(result => {
            return result.data;
        }).catch(error => {
            console.error(error);
            return null;
        })
    },

    getChapters(tags = undefined) {
        return apiClient.get( "/chapters" + (tags ? "?tags=" + tags  : "")).then(result => {
            return result.data.results
        })
    },
    getTags() {
        return apiClient.get( "/tags").then(result => result.data.results).then(tags => {
            return tags
        })
    },
    deleteChapter(chapter) {
        return apiClient.delete(`/chapters/${chapter.id}`)
    },
    saveChapter(chapter) {
        const _chapter = { ...chapter }
        if(chapter.content) {
            _chapter.content=_chapter.content.map(content=> ({file: content.file.id, path: content.path}))
        }
        _chapter.image = _chapter.image ? _chapter.image.id : undefined
        if(!chapter.id) {
            return apiClient.post("/chapters", _chapter).then(result => {
                return result.data
            })
        } else {
            const chapterId = chapter.id
            delete _chapter.id
            delete _chapter.createdAt
            delete _chapter.updatedAt
            delete _chapter.assignedTo
            delete _chapter.lastUpdatedBy
            delete _chapter.author
            return apiClient.patch(`/chapters/${chapterId}`, _chapter).then(result => {
                return result.data
            })
        }
    },
    getChapter(chapter) {
        return apiClient.get(`/chapters/${chapter.id}`).then(result => {
            return result.data
        })
    },
    deleteLesson(lesson) {
        return apiClient.delete(`/lessons/${lesson.id}`)
    },
    getLesson(lesson) {
        return apiClient.get(`/lessons/${lesson.id}`).then(result => {
            return result.data
        })
    },
    getLessons(tags = undefined) {
        return apiClient.get( "/lessons" + (tags ? "?tags=" + tags  : "")).then(result => {
            return result.data.results
        })
    },
    saveLesson(lesson) {
        const _lesson = { ...lesson }
        if(lesson.content) {
            _lesson.content=_lesson.content.map(content=> ({file: content.file.id, path: content.path}))
        }
        _lesson.image = _lesson.image ? _lesson.image.id : undefined
        if(!lesson.id) {
            return apiClient.post("/lessons", _lesson).then(result => {
                return result.data
            })
        } else {
            const lessonId = lesson.id
            delete _lesson.id
            delete _lesson.createdAt
            delete _lesson.updatedAt
            delete _lesson.assignedTo
            delete _lesson.lastUpdatedBy
            delete _lesson.author
            return apiClient.patch(`/lessons/${lessonId}`, _lesson).then(result => {
                return result.data
            })
        }
    },
    isChapterTitleTaken(chapterTitle) {
        return apiClient.get(`/chapters?name=${chapterTitle}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    getFiles() {
        return apiClient.get("/files");
    },
    getAllSammelGruppen(filter = []){
        let parameter = "";
        if(filter.length > 0){
            parameter += "?"+filter[0].name+"="+filter[0].value;
            for(let i=1; i < filter.length; i++){
                parameter += "&"+filter[i].name+"="+filter[i].value;
            }
        }
        return apiClient.get("/Collectiongroups"+parameter).then(result => {
            return result.data.results
        }).catch(error => {
            console.log("Error getting model", error)
            return [];
        });
    },
    createSammelgruppe(model){
        return apiClient.post("/Collectiongroups", model).then(result => {
            return result;
        }).catch(error => {
            console.log("Error creating model", error)
            return false
        });
    },
    deleteSammelgruppe(id){
        return apiClient.delete("/Collectiongroups/"+id).then(result => {
            if(result.data){
                return result.data
            }
            return {}
        }).catch(error => {
            console.log("Error deleting model", error)
            return Promise.reject(error)
        });
    },
    updateSammelgruppe(model, id){
        return apiClient.patch("/Collectiongroups/"+id, model).then(result => {
            return result;
        }).catch(error => {
            console.log("Error updating model", error)
            return Promise.reject(error)
        });
    },

    /* REST API für SMP Daten */
    getAllUser(filter = "", value = "") {
        let addFilter = "";
        if(filter != "" && value != ""){
            addFilter += "?"+filter+"="+value;
        }

        return apiClient.get("/api/kunde"+addFilter).then(result => {
            //console.log("Users:", result.data)
            return result.data.results
        })
    },
    getTelefon(id){
        return apiClient.get("/api/telefon?UKeyTelefon="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllTelefon() {
        return apiClient.get("/api/telefon").then(result => {
            //console.log("Users:", result.data)
            return result.data.results
        })
    },
    getModel(type, id){
        return apiClient.get("/api/"+type+"/"+id).then(result => {
            if(result.data){
                return result.data
            }
            return {}
        }).catch(error => {
            console.log("Error getting model", error)
            return {}
        });
    },
    deleteModel(type, id){
        if(type === "kunde"){
            firmaCache_valid = false;
            personCache_valid = false;
        }

        return apiClient.delete("/api/"+type+"/"+id).then(result => {
            if(result){
                return result
            }
            return {}
        }).catch(error => {
            console.log("Error deleting model", error)
            return Promise.reject(error)
        });
    },
    getAllModel(type, filter = []) {
        let parameter = "?limit=100000";
        if(filter.length > 0){
            parameter += "&"+filter[0].name+"="+filter[0].value;
            for(let i=1; i < filter.length; i++){
                parameter += "&"+filter[i].name+"="+filter[i].value;
            }
        }
        if(type === "kunde" && parameter.includes("KundeTyp=F") && firmaCache_valid){
            return firmaCache;
        }
        if(type === "kunde" && parameter.includes("KundeTyp=P") && personCache_valid){
            return personCache;
        }

        return apiClient.get("/api/"+type+""+parameter).then(result => {
            if(type === "kunde" && parameter.includes("KundeTyp=F")){
                firmaCache = result.data.results;
                firmaCache_valid = true;
            }
            if(type === "kunde" && parameter.includes("KundeTyp=P")){
                personCache = result.data.results;
                personCache_valid = true;
            }
            return result.data.results
        }).catch(error => {
            console.log("Error getting model", error)
            return [];
        });
    },
    createModel(type, model){
        console.log("create Model "+type);
        //delete REST values
        delete model.importedAt;
        delete model.RestTimestampCreate;
        delete model.RestTimestampLast;
        delete model.schemaType;

        if(type === "kunde" && model.KundeTyp === "F"){
            firmaCache_valid = false;
        }else if(type === "kunde" && model.KundeTyp === "P"){
            personCache_valid = false;
        }else{
            firmaCache_valid = false;
            personCache_valid = false;
        }

        console.log("send post");
        console.log(model)

        return apiClient.post("/api/"+type, model).then(result => {
            return result.data;
        }).catch(error => {
            console.log("Error creating model: ", error)
            return false
        });
    },
    updateModel(type, model, id){

        //delete REST values
        delete model.importedAt;
        delete model.RestTimestampCreate;
        delete model.RestTimestampLast;
        delete model.id;
        delete model.schemaType;
        delete model.createdAt;
        delete model.updatedAt;
        if(type !== 'telefon'){
            delete model.TelefonNr;
        }

        if(type === "kunde" && model.KundeTyp === "F"){
            firmaCache_valid = false;
        }else if(type === "kunde" && model.KundeTyp === "P"){
            personCache_valid = false;
        }else{
            firmaCache_valid = false;
            personCache_valid = false;
        }

        return apiClient.patch("/api/"+type+"/"+id, model).then(result => {
            return result.data;
        }).catch(error => {
            console.log("Error updating model", error)
            return false
        });
    },
    getKundeAp(id){
        return apiClient.get("/api/kundeap?UKeyKundeAp="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllKundeAp() {
        return apiClient.get("/api/kundeap?limit=100000").then(result => {
            //console.log("Gruppen:", result.data.results)
            return result.data.results
        })
    },
    getKundeOrAPWithInternetUser(email){
        //email = "stark001122@trigital.de";
        return apiClient.get("/api/kunde/?InternetUser="+email).then(result => {
            if(result.data.results.length > 0){
                return result.data.results[0];
            }else{
                return apiClient.get("/api/kundeap/?InternetUser="+email).then(result => {
                    if(result.data.results.length > 0){
                        return result.data.results[0];
                    }else {
                        return false;
                    }
                }).catch(error => {
                    console.log("Error getting model", error)
                    return false
                });
            }
        }).catch(error => {
            console.log("Error getting model", error)
            return false
        });
    },
    getLand(id){
        return apiClient.get("/api/land?UKeyLand="+id).then(result => {
            //console.log("Users:", result.data.results)
            if(result.data.results.length > 0){
                return result.data.results[0]
            }
            return {}
        })
    },
    getAllLand(){
        return apiClient.get("/api/land").then(result => {
            //console.log("Gruppen:", result.data.results)
            return result.data.results
        })
    },
    getKundeApsForFirma(UKeyF_Kunde, deep = false) {
        return apiClient.get("/api/kundeap?UKeyF_Kunde="+UKeyF_Kunde+"&deep="+deep).then(result => {
            //console.log("Users:", result.data)
            return result.data
        })
    },
    /*
    * Self Service
    * */
    getCompanyByUserId(userId) {
        return apiClient.get('/companies/by-kunde/'+userId).then(result => result.data)
            .catch(error => {
                if(error.response.status === 404) {
                    return null
                }
            })
    },
    saveCompany(company){
        try {
            const _company = { ...company }
            //delete _company.kunde;
            _company.info.storeWindowImage1 = (company.info.storeWindowImage1 && company.info.storeWindowImage1.id) ? company.info.storeWindowImage1.id : undefined;
            _company.info.storeWindowImage2 = (company.info.storeWindowImage2 && company.info.storeWindowImage2.id) ? company.info.storeWindowImage2.id : undefined;
            _company.info.storeWindowImage3 = (company.info.storeWindowImage3 && company.info.storeWindowImage3.id) ? company.info.storeWindowImage3.id : undefined;
            _company.info.storeWindowImage4 = (company.info.storeWindowImage4 && company.info.storeWindowImage4.id) ? company.info.storeWindowImage4.id : undefined;
            _company.logo = (company.logo && company.logo.id) ? company.logo.id : undefined;
            return apiClient.patch('/companies/by-kunde/'+company.kunde, _company).then(result => {
                return result.data
            }).catch(error => {
                console.error(error);
                return null;
            })
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    getAllSelfServiceGroups(){
        return apiClient.get("/ext/groups").then(result => {
            //console.log("Users:", result.data)
            return result.data.results;
        })
    },
    getMyGroups(){
        return apiClient.get("/ext/groups/my").then(result => {
            return result.data.results;
        })
    },
    addMeToGroup(groupId){
        return apiClient.post("/ext/groups/"+groupId+"/me").then(result => {
            return result.data;
        }).catch(error => {
            console.log("Error addung to group: ", error)
            return false
        });
    },
    removeMeFromGroup(groupId){
        return apiClient.delete("/ext/groups/"+groupId+"/me").then(result => result.data.results)

    },
    getTableSchema(table){
        for(let i=0; i<json.fieldmeta.length; i++){
            if(json.fieldmeta[i].table == table){
                return JSON.parse(json.fieldmeta[i].schema);
            }
        }
        let addTableName = "";
        if(table != ""){
            addTableName = "?table="+table;
        }
        return apiClient.get("/fieldmeta/"+addTableName).then(result => {
            //console.log("Schema1:", JSON.parse(result.data[0].schema))
            return JSON.parse(result.data[0].schema)
        })
    },
}
